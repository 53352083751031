<template>
  <modal class-name="flex flex-col w-full sm:max-w-500px" ref="modifyLoan">
    <h3 class="font-bold mb-2 text-xl">Modify Loan Application</h3>
    <p class="mb-10 text-gray-600">
      Update user loan parameters
    </p>
    <form @submit.prevent="modifyLoanData" class="flex flex-col flex-grow">
      <template v-if="getFormError(modifyLoan)">
        <div class="alert alert-red-soft mb-10">
          <span class="alert-icon">!</span>
          <span>{{ getFormError(modifyLoan) }}</span>
        </div>
      </template>
      <form-group
        v-model="modifyLoan.data.requested_amount.value"
        :form="modifyLoan"
        name="requested_amount"
        type="money"
      >
        Requested Amount
      </form-group>

      <form-group
        v-model="modifyLoan.data.tenure.value"
        :form="modifyLoan"
        name="tenure"
        type="money"
      >
        Loan Tenure
      </form-group>

      <form-group
        v-model="modifyLoan.data.interest_rate.value"
        :form="modifyLoan"
        name="interest_rate"
        type="number"
      >
        Loan Interest Rate
      </form-group>

      <form-group
        v-model="modifyLoan.data.comments.value"
        :form="modifyLoan"
        name="comments"
        type="textarea"
      >
        Type comments here
      </form-group>

      <div class="flex items-center gap-4 mt-auto">
        <button
          type="button"
          class="btn btn-gray btn-md"
          @click.prevent="$refs.modifyLoan.close"
        >
          Cancel
        </button>
        <button
          type="submit"
          class="btn btn-blue btn-md w-full"
          :disabled="modifyLoan.loading"
        >
          <sm-loader-white v-if="modifyLoan.loading" />
          <span v-else>Modify</span>
        </button>
      </div>
    </form>
  </modal>
</template>

<script>
export default {
  props: {
    loan: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      modifyLoan: this.$options.basicForm([
        { name: 'requested_amount', rules: 'required|number|min:30000' },
        { name: 'tenure', rules: 'required|number|min:1|max:12' },
        {
          name: 'interest_rate',
          rules: 'required|numeric|max:100|min:0.1'
        },
        { name: 'comments', rules: 'required' }
      ])
    };
  },
  computed: {
    loanId() {
      return this.loan?.id;
    }
  },
  methods: {
    async modifyLoanData() {
      if (!this.validateForm(this.modifyLoan)) return false;

      this.modifyLoan.loading = true;
      await this.sendRequest('admin.loans.personal.modify', this.loanId, {
        data: this.getFormData(this.modifyLoan),
        success: response => {
          const { data } = response?.data;
          this.$success({
            title: 'Operation Successful',
            body: 'Loan Modified and Approved Successfully',
            button: 'View Offer Letter',
            onClose: () => {
              window.open(
                this.$router.resolve({
                  name: 'offer-letter',
                  params: {
                    loanId: data.id
                  }
                }).href,
                '_blank'
              );
            }
          });
          this.$parent?.$emit('success');
        },
        error: error => {
          this.modifyLoan.error = error;
          this.mapFormErrors(this.modifyLoan, error.response?.data?.errors);
        }
      }).finally(() => (this.modifyLoan.loading = false));
    },
    open() {
      this.$refs.modifyLoan.open();
    },
    mapLoanData() {
      for (let key in this.modifyLoan.data) {
        this.modifyLoan.data[key].value = this.loan[key] || '';
      }
    }
  },
  mounted() {
    this.mapLoanData();
  }
};
</script>
